import React, { Component, Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Div from '../components/Div'
import H5 from '../components/H5'
import Body2 from './../components/Body2'

import ImageStepCloud1 from '../images/cloud-server/img-step-1@3x.png'
import ImageStepCloud2 from '../images/cloud-server/img-step-2@3x.png'
import ImageStepCloud3 from '../images/cloud-server/img-step-3@3x.png'

import { 
  circle,
} from '../components/styles/CloudServer.styles'

const StepsCloudServerItem = ({
  stepNumber,
  titleStep,
  imgStep,
  contentStep,
}) => {
  return (
    <div>
      <Div
        flexDirection='column'
        alignItems='center'
        maxWidth={['100%', 680]}
        style={{
          margin: '0 auto'
        }}
      >
        <div
          css={circle}
          style={{
            marginTop: 6
          }}
        >
          {stepNumber}
        </div>
        <H5
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            marginBottom: 24
          }}
        >
          {titleStep}
        </H5>
        <div>
          <img src={imgStep} />
        </div>
        <Body2 
          style={{ 
            textAlign: 'center',
            lineHeight: 1.71,
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: 14,
            marginBottom: 24
            // width: 520
          }}>
          {contentStep}
        </Body2>
      </Div>
    </div>
  )
}

export default class SimpleSlider extends Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      outline: 'none',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    }
    return (
      <Fragment>
        <div style={{ marginBottom: 50 }}>
          <Slider {...settings}>
            <StepsCloudServerItem
              stepNumber={'1'}
              titleStep={'Criar seu cadastro'}
              imgStep={ImageStepCloud1}
              contentStep={'O Cloud Server Hostdime só pode ser acessado a partir da nossa área de cliente CORE. Você pode criar um perfil simples com informações básicas de contato ou um completo, para um suporte ainda mais personalizado. Também poderá adicionar contatos autorizados para acessar sua conta.'}
            />
            <StepsCloudServerItem
              stepNumber={'2'}
              titleStep={'Ativar conta'}
              imgStep={ImageStepCloud2}
              contentStep={'O serviço funciona por dedução de crédito calculada pelo uso/hora do serviço Cloud. Após confirmar seu e-mail, acesse a área do cliente CORE e adicione a partir de R$75 (valor mínimo) para elevar seu potencial para as nuvens. Aceitamos pagamentos em boleto, cartão de crédito, Paypal e Bitcoin.'}
            />
            <StepsCloudServerItem
              stepNumber={'3'}
              titleStep={'Comece a usar!'}
              imgStep={ImageStepCloud3}
              contentStep={'Através de nossa plataforma moderna e intuitiva, você poderá gerenciar a sua nuvem em tempo real, aumentando ou diminuindo seus ativos computacionais de acordo com a sua demanda. Alta disponibilidade e segurança pagando apenas pelo que usar, quando usar.'}
            />
          </Slider>
        </div>
      </Fragment>
    )
  }
}