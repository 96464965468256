import React, { Fragment, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import { graphql, Link }  from 'gatsby'
import compose from 'lodash/fp/flowRight'
import ArrowR from '@material-ui/icons/ArrowForward'
import ThumbUp from '@material-ui/icons/ThumbUpAlt'
import ThumbDown from '@material-ui/icons/ThumbDownAlt'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import Layout from '../components/Layout'
import ds from '../theme'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import ContainedButton from '../components/ContainedButton'
import OutlineButton from '../components/OutlinedButton'
import Hr from '../components/Hr'
import H2 from '../components/H2'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import Subtitle1 from '../components/SubTitle'
import Body1 from './../components/Body1'
import Body2 from './../components/Body2'
import ArrowDown from '../images/arrow-down-24-px.png'
import ArrowRight from '../images/arrow-right-24-px.png'

import { Container, mq } from '../components/styles/Layout.styles'
import { css } from '@emotion/core'
import { withSolutions, SolutionContext } from '../components/SolutionProvider'
import PassosCloudServer from '../components/StepsCloudServer'
import ScrollAnim from 'rc-scroll-anim'

import { 
  styledCloudServer,
  header,
  highlightBox,
  stepsCloud,
  steptCloudItem,
  listCloudItem,
  faqCloudServer,
  table,
  iconeX
} from '../components/styles/CloudServer.styles'
import { titleStyle, titleForm } from '../components/styles/InternalPage.styles'
import { 
  termosBox
} from '../components/styles/Contact.styles'
import CloudServerForm from '../components/ContactForm2'
import { validateRequiredEmail } from '../utils/validations';
const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: 'CNPJ', 
    name: 'your-cnpj',
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: (
      <span css={termosBox}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const initialModals = {
  support: false,
  moreInfo: false
}

const Solution = ({
  title,
  children,
  id,
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#FFFFFF',
        borderRadius: 16,
        transition: 'height 0.2s',
        cursor: 'default',
        
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: 24,
            height: 24,
            backgroundImage: isActive ? `url(${ArrowDown})` : `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginRight: ds.space(3)
          }}
        />
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            display: 'flex',
            color: 'rgba(0, 0, 0, 0.87)',
            lineHeight: 1.59,
            fontSize: 13.8,
            fontWeight: 600,
            height: 63,
            alignItems: 'center'
          }}
        >
          {title}
        </Body1>
      </Div>
      {children && (
        <Div
          style={{
            height: isActive ? 'auto' : 0,
            opacity: isActive ? 1 : 0,
            transition: isActive ? 'height 0.2s, opacity 0.2s 0.1s' : 'height 0.2s 0.1s, opacity 0.2s',
            zIndex: isActive ? 1 : -1,
            position: 'relative'
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[34.5, 35, 68, 46]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['none', 'none', 'flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                >
                  <H4
                    style={{
                      fontSize: 11.8,
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      marginBottom: 8
                    }}
                  >
                    CLOUD SERVER HOSTDIME
                  </H4>
                  <H2
                    color='black'
                    variant='highEmphasis'
                    mb={[5, 5, 3]}
                    css={titleStyle}
                  >
                    Eleve seu Potencial
                  </H2>
                  <Div
                    display={['block', 'block', 'none']}
                    mb={[5]}
                    maxWidth={[310, 310, 327, 360]}
                  >
                    <Img
                      fluid={data.image1.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    mb={[0, 0, 3]}
                    maxWidth={['90%', 422, 416, 416]}
                  >
                    Amplie todas as possibilidades do seu negócio através de uma plataforma moderna e intuitiva que permite a criação e o gerenciamento de nuvem em tempo real, pagando pelo que usar, apenas quando usar, como: máquinas virtuais, firewall, VPN, balanceadores de carga, entre outros.
                    <Div
                      mt={[5]}
                      alignItems={['center', 'center', 'start', 'start']}
                    >
                      <ContainedButton
                        type='btnContainedLarge'
                        Link='/precos-cloud-server'
                      >
                        VER PREÇOS E PLANOS
                      </ContainedButton>
                    </Div>
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <section css={styledCloudServer}>
        <Container>
          <Row
            mt={30}
            mb={49.5}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              css={highlightBox}>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Otimização de Custos</span>
                </div>
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Autonomia de Gerenciamento</span>
                </div>
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Flexibilidade de Recursos</span>
                </div>
              </Column>
              <Column
                width={[1, 1, 1/2, 1/2]}
              >
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Confiabilidade & Alta<br /> Disponibilidade</span>
                </div>
                <div css={listCloudItem}>
                  <ArrowR 
                    style={{
                      color: 'rgba(255, 255, 255, 0.54)',
                      marginRight: 16
                    }}
                  />
                  <span>Segurança da Informação</span>
                </div>
              </Column>
            </Div>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            css={header}
            justifyContent={'center'}
            mt={[40, 45.5, 39.5, 39.5]}
            mb={18}
          >
            <Column>
              <H4 style={{ marginBottom: 16 }}>Utilizando o Cloud<br /> Server HostDime</H4>
              <Paragraph>Os seus primeiros passos com nossos <br />serviços em nuvem</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column']}
            css={stepsCloud}
            mb={30}
          >
            <Column
              width={[1]}
            >
              <Div
                maxWidth={['100%', '100%', 580]}
                style={{
                  margin: '0 auto'
                }}
              >
                <PassosCloudServer />
              </Div>
            </Column>
          </Row>
          <Div
            css={steptCloudItem}
          >
            <ScrollAnim.Link
              to='quero-testar'
              offsetTop='120'
            >
              <OutlineButton
                type='btnOutlinedMedium'
              >
                QUERO TESTAR
              </OutlineButton>
            </ScrollAnim.Link>
          </Div>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5,
                marginTop: 50
              }}
            />
          </Div>
          <Row
            css={header}
            mt={[40, 45.5, 39.5, 39.5]}
            mb={30}
          >
            <Column>
              <H4 style={{ marginBottom: 16 }}>A escalabilidade da nuvem</H4>
              <Paragraph style={{ marginBottom: 16, textAlign: 'left' }}>VPS tradicionais são uma ótima solução, no entanto pecam quando se trata de escalabilidade de recursos e alta disponibilidade. Em contrapartida, o Cloud Server HostDime possui uma ampla infraestrutura que permite o aumento ou diminuição de ativos computacionais de acordo com a demanda. Uma plataforma elástica que provê e gerencia processamento, memória, largura de banda e armazenamento 100% em SSD automaticamente, sem necessidade de reboot.</Paragraph>
              <Paragraph style={{ textAlign: 'left' }}>Além disso, todo o ambiente do Cloud Server HostDime provê alta disponibilidade, garantindo os recursos necessários para que seus serviços estejam sempre operantes e em funcionamento. Assim, você não fica mais refém de um único hardware, pois quando um falha, automaticamente todas as máquinas virtuais migram para outro hardware disponível, sem indisponibilidade de serviços.</Paragraph>
            </Column>
          </Row>
          <Row
            mt={30}
            mb={26}
            flexDirection={['row']}
          >
            <Div 
              css={table}
            >
              <Column>
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: 1.29,
                        textAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.87)',
                        marginRight: 16
                      }}
                    >
                      Cloud Server<br /> HostDime
                    </span>
                    <span
                      style={{
                        fontSize: 14,
                        lineHeight: 1.29,
                        textAlign: 'center',
                        color: 'rgba(0, 0, 0, 0.87)'
                      }}
                    >
                      VPS<br />Tradicional
                    </span>
                  </div>
                </Div>
              </Column>  
              <Column
                width={[1]}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Escolha de recursos específicos</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Ativação instantânea</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Escalabilidade sob demanda</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Alta disponibilidade e<br /> balanceamento de carga</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Gerenciamento de tráfego</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                  style={{
                    marginBottom: 24
                  }}
                >
                  <Subtitle1>Pague pelo o que usar, quando usar</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
                <Hr
                  lineColor='lightPeriwinkle'
                  maxWidth={[328, '100%', 680, 680]}
                  style={{
                    opacity: 0.5
                  }}
                />
              </Column>
              <Column
                width={[1]}
                mt={16}
              >
                <Div
                  flexDirection={['row']}
                  alignItems={['center']}
                >
                  <Subtitle1>Prevenção avançada contra<br /> ameaças de rede</Subtitle1>
                  <div style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    <ThumbUp 
                      style={{
                        color: '#50e3dc'
                      }}
                    />
                    <span css={iconeX}>x</span>
                    <ThumbDown 
                      style={{
                        color: '#d9dee3',
                        marginRight: 32
                      }}
                    />
                  </div>
                </Div>
              </Column>
            </Div>
          </Row>
          <Div
            css={steptCloudItem}
            style={{
              marginBottom: 70
            }}
          >
            <OutlineButton
              type='btnOutlinedLarge'
              Link='/precos-cloud-server'
            >
              CONFIGURE SUA CLOUD
            </OutlineButton>
          </Div>
        </Container> 
      </section>
      <section css={faqCloudServer}>
        <Container>
          <Row
            mt={40}
            mb={40}
            justifyContent='center'
          >
            <H4>Tire suas Dúvidas</H4>
          </Row>
          <Row
            mb={50}
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Column
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', 422, 422]}
              >
                <Solution
                  title='Por que eu tenho que fazer um depósito para obter uma conta do Cloud Server HostDime?'
                  id='solution01'
                >
                  O serviço de Cloud da HostDime utiliza um sistema de dedução de crédito. Dessa forma, você precisa adicionar créditos ao sistema para fazer utilização do serviço. Você pode adicionar a partir de R$ 75,00 (valor mínimo). Uma vez que a cobrança é efetuada por hora, o sistema registrará sua utilização por hora e deduzirá esse valor de seus créditos. 
                </Solution>
                <Solution
                  title='Preciso monitorar constantemente meu saldo de crédito e recarregar manualmente minha conta?'
                  id='solution02'
                >
                  Você pode configurar em seu painel do cliente para que o sistema emita um alerta quando o saldo estiver abaixo do limite definido. Dessa forma, uma fatura de recarga será gerada automaticamente. 
                </Solution>
                <Solution
                  title='Que tipos de pagamentos vocês aceitam?'
                  id='solution12'
                >
                  São aceitos os métodos de pagamento definidos nos termos de serviço: cartão de crédito, PayPal, boleto bancário e Bitcoin.
                </Solution>
                <Solution
                  title='Como sou cobrado pelas minhas instâncias?'
                  id='solution03'
                >
                  Todo o faturamento é feito a cada hora. 
                </Solution>
                <Solution
                  title='Sou cobrado por instâncias paradas?'
                  id='solution04'
                >
                  Instâncias paradas são cobradas apenas pelo espaço em disco alocado. 
                </Solution>
                <Solution
                  title='Por que minha estimativa mensal é diferente do valor da minha cobrança real?'
                  id='solution05'
                > 
                  A estimativa mensal que exibimos é baseada em 730h/mês. Alguns meses têm mais de 730 horas, outros têm menos. 
                </Solution>         
              </Div>
            </Column>
            <Column
              mt={[8, 8, 0, 0]}
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', 422, 422]}
              >
                <Solution
                  title='O crédito da HostDime é diferente do crédito da HostDime Cloud?'
                  id='solution06'
                >
                  Sim, os créditos em nuvem só podem ser usados para o(s) produto(s) da nuvem, O crédito do HostDime Client pode ser usado para comprar crédito da HostDime Cloud.
                </Solution>
                <Solution
                  title='Posso converter o crédito do HostDime em crédito no HostDime Cloud?'
                  id='solution07'
                >
                  Você pode gerar uma fatura para o crédito da HostDime Cloud e usar o crédito para pagar essa fatura. Não há nenhum botão de “converter” atualmente. 
                </Solution>
                <Solution
                  title='Como é faturada a utilização de banda nas instâncias?'
                  id='solution08'
                >
                  No DC Orlando, toda nova instância recebe gratuitamente 10TB de banda. No DC Brasil, novas instâncias recebem gratuitamente 10 TB de tráfego outbound e 5 TB de tráfego inbound. 
                </Solution>
                <Solution
                  title='Qual o valor da largura de banda excedente, caso eu ultrapasse os 10 TB?'
                  id='solution09'
                >
                  No DC Orlando, a banda inbound é free e a banda outbound custa R$ 0,04 por GB excedente. No DC João Pessoa, o GB de banda inbound excedente custa R$ 0,08, e o GB de banda outbound é R$ 0,04.
                </Solution>
                <Solution
                  title='Como entrar no meu portal HostDime Cloud diretamente?'
                  id='solution10'
                >
                  Você só pode acessar seu portal do HostDime Cloud através do CORE, no seguinte endereço: https://core.hostdime.com.br/cloud/
                </Solution>
                <Solution
                  title='Vocês oferecem o IPv6?'
                  id='solution11'
                >
                  Nós oferecemos um prefixo /64 por cliente, livre de custos. O IPv6 é atualmente um produto não-SLA e todos os clientes devem reconhecer especificamente isso por meio de nosso suporte técnico antes da atribuição de sub-rede.
                </Solution>            
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <section id='quero-testar'>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[34.5, 35, 70, 70]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'end']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['none', 'none', 'flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                >
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                  maxWidth={['100%', 422, 400, 400]}
                >
                  <H4
                    color='black'
                    variant='highEmphasis'
                    mb={[5, 5, 3]}
                    css={titleForm}
                  >
                    Descubra um ambiente na nuvem com gerenciamento inteligente
                  </H4>
                  <Div
                    display={['block', 'block', 'none']}
                    mb={[5]}
                    maxWidth={[310, 310, 327, 360]}
                  >
                    <Img
                      fluid={data.image2.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    mb={[0, 0, 3]}
                    maxWidth={['100%', 422, 416, 416]}
                    alignItems={['center', 'center', 'normal', 'normal']}
                  >
                    Experimente a recarga mínima com 50% off
                    <Div
                      style={{
                        marginTop: 40,
                        marginBottom: 70,
                        textAlign: 'center'
                      }}
                    >
                      <CloudServerForm
                        fields={fields}
                        formId={4357}
                        submitText='Solicitar meu cupom'
                      />
                    </Div>
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo 
        title='Cloud Server HostDime | Crie sua nuvem'
        description='Hospede na nuvem pagando apenas o que usar, apenas quando usar: máquinas virtuais, firewall, VPN, balanceadores de carga, entre outros.'
      />
      <PageTemplate
        title='Cloud Server Hostdime'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "cloud-server/descubra-cloud-server.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const enhance = compose(
  withSolutions
)

export default enhance(StaticPage)
